import React, { forwardRef, useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  FormGroup,
  // Card,
  // ListGroup,
  // Accordion,
  Container,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
import { format } from "date-fns";
// import { submitForm } from "../../../../../utils/submitForm";
// import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import ApplicationSummary from "./ApplicationSummary";
import { API_BASE_URL } from "../../../../../utils/constants";
import axios from "axios";
// import ApplicationReview from "./ApplicationReview";
import {
  fetchContactData,
  fetchContactDataByEmail,
} from "../../../../../hooks/apiHelper";
import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
import ContactContext from "../../../../../context/Contacts/ContactContext";
import ApplicationReviewWidget from "./ApplicationReviewWidget";
import {
  calculateArtistTier,
  calculateOpportunityValue,
} from "./opportunityValueCalculator";
import { submitForm } from "../../../../../utils/submitForm";
import cleanFormData from "../../../../../utils/cleanFormData";
// import TalentScoutSummary from "./TalentScoutSummary";

// import { AskArtistAi } from "../../api";

export function HiddenTrackingFields({
  testmode = false,
  onDataUpdate,
  parentUrlParams,
}) {
  // console.log("testmode:", testmode);

  // get query params
  const queryParams = new URLSearchParams(window.location.search);

  let params = {};
  // combine parentUrlParams and queryParams into one object
  if (parentUrlParams) {
    params = { ...parentUrlParams };
  }
  if (queryParams) {
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
  }
  console.log("parentUrlParams:", parentUrlParams);
  console.log("queryParams:", queryParams);

  console.log("params:", params);
  // Use the combined 'params' object to get individual values
  const utm_campaign = params["utm_campaign"] ?? "";
  const utm_source = params["utm_source"] ?? "";
  const utm_medium = params["utm_medium"] ?? "";
  const ad_campaign_id = params["ad_campaign_id"] ?? "";
  const adset_id = params["adset_id"] ?? "";
  const ad_id = params["ad_id"] ?? "";
  const ad_campaign_name = params["campaign_name"] ?? "";
  const adset_name = params["adset_name"] ?? "";
  const ad_name = params["ad_name"] ?? "";
  const site_source_name = params["site_source_name"] ?? "";
  const placement = params["placement"] ?? "";
  const application_source = params["application_source"] ?? "";

  // Function to handle changes in the hidden fields
  const handleInputChange = (event) => {
    return;
    // const { name, value } = event.target;
    // // Call onDataUpdate with the updated values
    // onDataUpdate((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
  };

  useEffect(() => {
    onDataUpdate({
      utm_campaign,
      utm_source,
      utm_medium,
      ad_campaign_id,
      adset_id,
      ad_id,
      ad_campaign_name,
      adset_name,
      ad_name,
      site_source_name,
      placement,
      application_source,
    });
  }, [
    utm_campaign,
    utm_source,
    utm_medium,
    ad_campaign_id,
    adset_id,
    ad_id,
    ad_campaign_name,
    adset_name,
    ad_name,
    site_source_name,
    placement,
    application_source,
    onDataUpdate,
  ]);

  return (
    <Form.Group>
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="ad_campaign_id"
        placeholder="Ad Campaign ID"
        value={ad_campaign_id}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="utm_campaign"
        placeholder="UTM Campaign"
        value={utm_campaign}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="utm_medium"
        placeholder="UTM Medium"
        value={utm_medium}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="utm_source"
        placeholder="UTM Source"
        value={utm_source}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="campaign_name"
        placeholder="Campaign Name"
        value={ad_campaign_name}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="ad_name"
        placeholder="Ad Name"
        value={ad_name}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="adset_name"
        placeholder="Adset Name"
        value={adset_name}
        onChange={handleInputChange}
      />

      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="site_source_name"
        placeholder="Site Source Name"
        value={site_source_name}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="placement"
        placeholder="Placement"
        value={placement}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="adset_id"
        placeholder="Adset ID"
        value={adset_id}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="ad_id"
        placeholder="Ad ID"
        value={ad_id}
        onChange={handleInputChange}
      />
      <Form.Control
        type={testmode ? "text" : "hidden"}
        name="application_source"
        placeholder="Application Source"
        value={application_source}
        onChange={handleInputChange}
      />
    </Form.Group>
  );
}

export const AskArtistAi = (ws, properties) => {
  const requestPayload = {
    type: "requestCompletion",
    data: properties,
  };

  ws.send(JSON.stringify(requestPayload));
};

export function getFullName(firstName = "", lastName = "") {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  }
  return "";
}
// Function to check if a string is a valid URL
export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (error) {
    try {
      // Try prepending "https://" if not included
      url = new URL("https://" + string);
      return true; // URL is valid with "https://" prefix
    } catch (error) {
      return false; // URL is invalid
    }
  }
  return (
    (url.protocol === "http:" || url.protocol === "https:") &&
    url.hostname.includes(".")
  );
};

export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

function convertDateToSlashes(dateStr) {
  return dateStr.replace(/-/g, "/");
}

export const ArtistApplication = ({
  setCompleted,
  testmode = false,
  // setApplicationSummary,
  // applicationSummary,
  outcomes = {
    qualified: "https://masterclass.modernmusician.me/breakthrough",
    disqualified: "https://masterclass.modernmusician.me/application-submitted",
    discovery: "https://modernmusician.streetteam.fm/posts/lnbswl39a58o",
  },
}) => {
  // console.log("hello world");
  // const [artistSummary, setArtistSummary] = useState("");

  // const [talentScoutSummary, setTalentScoutSummary] = useState("");
  // const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  const [contactData, setContactData] = useContext(ContactContext);

  const [hiddenFieldsData, setHiddenFieldsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stepConfig = [
    { step: 0, requiredFields: ["name"], skipIf: null },
    { step: 1, requiredFields: ["email"], skipIf: null },
    { step: 2, requiredFields: ["song_link"], skipIf: null },
    { step: 3, requiredFields: ["genre"], skipIf: null },
    { step: 4, requiredFields: ["artist_description"], skipIf: null },
    { step: 5, requiredFields: ["fanbase_size"], skipIf: null },
    { step: 6, requiredFields: ["monthly_revenue"], skipIf: null },
    { step: 7, requiredFields: ["sustained_by_music"], skipIf: null },
    { step: 8, requiredFields: ["target_monthly_revenue"], skipIf: null },
    { step: 9, requiredFields: ["annual_goals"], skipIf: null },
    { step: 10, requiredFields: ["roadblocks"], skipIf: null },
    { step: 11, requiredFields: ["coachable"], skipIf: null },
    { step: 12, requiredFields: ["investable_time"], skipIf: null },
    { step: 13, requiredFields: ["belief"], skipIf: null },
    {
      step: 14,
      requiredFields: ["resourceful"],
      skipIf: null,
    },
    {
      step: 15,
      requiredFields: ["investable_budget"],
      skipIf: (state) => state.resourceful === "Yes",
    },
    { step: 16, requiredFields: ["start_date"], skipIf: null },
    { step: 17, requiredFields: ["secret_passcode"], skipIf: null },
  ];
  // const {locationData} = useContext(LocationContext);
  // const [contactData, setContactData] = useState({});

  const [existingApplication, setExistingApplication] = useState(
    contactData?.breakthrough?.application?.fields ?? null
  );
  const [currentStep, setCurrentStep] = useState(contactData?.email ? 2 : 0);
  const [formData, setFormData] = useState({
    // Step 1: What's your name? Email?

    // name: !existingApplication?.owner?.name
    //   ? ""
    //   : existingApplication.owner.name,

    name: getFullName(contactData?.first_name, contactData?.last_name),

    email: contactData?.email ?? "",

    // Step 2: Please share a link to stream one of your songs
    song_link: existingApplication?.funnel_links?.streaming ?? "",

    // Step 3: Which genre best describes your music?
    genre: existingApplication?.genre ?? "",

    // Step 3: Tell me briefly about your music career. We can only accept a limited number of sessions due to high demand. What makes you different from the other applications and why should we choose to speak with you?
    artist_description: existingApplication?.artist_description ?? "",

    // Step 4: What's the current size of your fanbase?
    fanbase_size: existingApplication?.fanbase_size ?? "",

    // Step 5: On average, how much are you making per month from music?

    monthly_revenue: existingApplication?.monthly_revenue ?? "",

    // Step 6: Are you currently supporting yourself full-time with your music income?
    sustained_by_music: existingApplication?.sustained_by_music ?? "",

    // Step 7: How much would you need to make per month to fully replace your existing income?
    target_monthly_revenue: existingApplication?.target_monthly_revenue ?? "",

    // Step 8: If we were having a discussion a year from today, and you were looking back on the past 12 months, what would have had to happen in both your life and career, for you to be excited about your progress?
    annual_goals: existingApplication?.annual_goals ?? "",

    // Step 9: Be 100% honest - What do you think is stopping you from reaching your goals? (The more specific you are here the faster we'll be able to get you clarity during our call together)
    roadblocks: existingApplication?.roadblocks ?? "",

    // Step 10: I'm looking for a few coaching clients to work with my team personally. If you're a good fit, would you be interested in working together?
    coachable: existingApplication?.coachable ?? "",

    // Step 11: How many hours do you (or a team member) have available to focus on your music per day?
    investable_time: existingApplication?.investable_time ?? "",

    // Step 12: If you bring in ${formatted_target_monthly_sales} fans per month who pay you $5, you'll earn $${formatted_target_monthly_revenue}/m in revenue. With an experienced mentor, do you believe you can achieve this or more?
    belief: existingApplication?.belief ?? "",

    // Step 13: The minimum investment for 1:1 coaching with Modern Musician is $5k. During our call, if we both decide we'd like to move forward, are you able to do this? (We also have a $1500/mo payment plan available)
    resourceful: existingApplication?.resourceful ?? "",

    // Step 14: What is your available budget per month to invest in growing your music career? (including recording, music videos, promotion, etc.)
    investable_budget: existingApplication?.investable_budget ?? "",

    // Step 15: If you are accepted, how soon can you get started?
    start_date:
      existingApplication?.start_date ?? format(new Date(), "MM-dd-yyyy"),

    // Step 16: What's the secret passcode? (Just leave this blank if you don't know it yet.)
    secret_passcode: existingApplication?.secret_passcode ?? "",
  });

  const [parentUrlParams, setParentUrlParams] = useState(null);

  // const [start_date, setstart_date] = useState(new Date());
  const [isFullTimeMusicIncome, setIsFullTimeMusicIncome] = useState(null);
  const [resourceful, setResourceful] = useState("");
  const [showError, setShowError] = useState(false); // New state variable for UI
  // const [ws, setWs] = useState(null);
  // const [summaryChunks, setSummaryChunks] = useState([]);

  // let owner_name = !existingApplication?.owner?.name
  //   ? ""
  //   : existingApplication.owner.name

  // const handleButtonClick = () => {
  //   const contactId = "1Ta08j8DixlezOnp1A5k"; // Replace with the actual contact ID
  //   window.open(`/test/applications?contact_id=${contactId}`);
  // };

  // //websockets
  // useEffect(() => {
  //   if (existingApplication?.id) {
  //     const ws = new WebSocket(
  //       `wss://colorful-idea-n191w.ampt.app?type=application_summary&name=${owner_name}`
  //     );

  //     ws.onopen = () => {
  //       console.log("Connected to the WebSocket server");
  //     };

  //     ws.onmessage = (event) => {
  //       console.log("Message received from server: ", event.data);
  //       const data = JSON.parse(event.data);
  //       if (data.type === "applicationSummariesChunk") {
  //         setIsGeneratingSummary(true);
  //         // console.log("data:", data);
  //         let chunkContent = data.chunkContent;
  //         console.log("chunkContent:", chunkContent);

  //         // let jsonChunk = data.chunkContent;

  //         // Extract the content from the chunkContent object
  //         // let jsonData = JSON.parse(chunkContent);
  //         // console.log("jsonData:", jsonData);

  //         // console.log("chunkMessage:", chunkMessage);
  //         if (chunkContent) {
  //           setApplicationSummary((prevChunks) => [
  //             ...prevChunks,
  //             chunkContent,
  //           ]);
  //         }
  //         setIsGeneratingSummary(false);
  //       }
  //     };

  //     setWs(ws);
  //   }
  // }, [existingApplication?.id, owner_name, setApplicationSummary]);

  // useEffect(() => {
  //   return () => {
  //     ws && ws.close();
  //   };
  // }, [ws]);

  useEffect(() => {
    // if (artistSummary && talentScoutSummary) {
    //   setCompleted(true);
    // }

    const fetchAndSetData = async () => {
      // setIsLoading(true);
      // const fetchTestContactData = async (email) => {
      //   let fetchedContactData = await fetchContactDataByEmail(
      //     email,
      //     MM_CRM_LOCATION_ID
      //   );

      //   return fetchedContactData;
      // };
      if (testmode && !existingApplication) {
        // setIsLoading(true);

        // fetchTestContactData("mm.radioactive.tester@gmail.com").then((data) => {
        //   console.log("data:", data);
        //   setExistingApplication(
        //     data?.breakthrough?.application?.fields ?? null
        //   );
        //   setContactData(data);
        // });
        setFormData({
          // Step 1: What's your name? Email?
          name: "Radioactive Tester App",
          email: "mm.radioactive.tester@gmail.com",

          // Step 2: Please share a link to stream one of your songs
          song_link: "https://soundcloud.com/janedoe/track1",

          // Step 3: Tell me briefly about your music career. What makes you different?
          artist_description:
            "I've been a singer-songwriter for 5 years, specializing in indie rock. What sets me apart is my unique blend of folk and electronic elements. I've been a singer-songwriter for 5 years, specializing in indie rock. What sets me apart is my unique blend of folk and electronic elements.",

          // Step 3: Which genre best describes your music?
          genre: "Rock",

          // Step 4: What's the current size of your fanbase?
          fanbase_size: "1,000 to 10,000",

          // Step 5: On average, how much are you making per month from music?
          monthly_revenue: "$1,000 to $10,000",

          // Step 6: Are you supporting yourself full-time with your music income?
          sustained_by_music: "No",

          // Step 7: How much would you need to make to fully replace your existing income?
          target_monthly_revenue: "5000",

          // Step 8: If we were having a discussion a year from today, what would have had to happen for you to be excited about your progress?
          annual_goals:
            "Release an EP, grow my fanbase by 50%, and secure a record deal",

          // Step 9: Be 100% honest - What's stopping you from reaching your goals?
          roadblocks:
            "Lack of marketing knowledge and myself. I need a mentor.",

          // Step 10: I'm looking for a few coaching clients to work with my team personally. If you're a good fit, would you be interested in working together?
          coachable: "Yes",

          // Step 11: How many hours do you have available to focus on your music per day?
          investable_time: "1 to 2 hours",

          // Step 12: If you bring in X fans who pay you $5 per month, do you believe you can achieve this or more?
          belief: "Yes",

          // Step 13: The minimum investment for 1:1 coaching with Modern Musician is $5k. During our call, if we both decide we'd like to move forward, are you able to do this? (We also have a $1500/mo payment plan available)
          resourceful: "Yes",

          // Step 14: What is your available budget per month to invest in growing your music career?
          investable_budget: "2000",

          // Step 15: If you are accepted, how soon can you get started?

          // hot start_date
          start_date: format(new Date(), "MM-dd-yyyy"),

          // cold start_date
          // start_date: format(
          //   new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000),
          //   "MM-dd-yyyy"
          // ),
        });
        setCurrentStep(3);
        setIsLoading(false);
      }

      // if parentUrlParams includes crm_id or email, fetch contact data and set it
      if (
        (parentUrlParams?.crm_id || parentUrlParams?.email) &&
        !testmode &&
        !existingApplication
      ) {
        setIsLoading(true);
        if (parentUrlParams?.email) {
          setFormData((prevData) => ({
            ...prevData,
            name: getFullName(
              parentUrlParams?.first_name,
              parentUrlParams?.last_name
            ),
            email: parentUrlParams?.email ?? "",
          }));
        }
        let email = parentUrlParams?.email;
        let crm_id = parentUrlParams?.crm_id;
        let fetchedContactData = null;
        if (email) {
          fetchedContactData = await fetchContactDataByEmail(
            email,
            MM_CRM_LOCATION_ID
          );
        } else if (crm_id) {
          fetchedContactData = await fetchContactData(
            crm_id,
            MM_CRM_LOCATION_ID
          );
        }
        if (fetchedContactData) {
          setExistingApplication(
            fetchedContactData?.breakthrough?.application?.fields ?? null
          );
          setContactData(fetchedContactData);
          // set the formData
          setFormData((prevData) => ({
            ...prevData,
            name: getFullName(
              fetchedContactData?.first_name,
              fetchedContactData?.last_name
            ),
            email: fetchedContactData?.email ?? "",
          }));
          setCurrentStep(2);
        }
      }
      setIsLoading(false);
    };

    fetchAndSetData();
    // setIsLoading(false);
  }, [testmode, setContactData, parentUrlParams, existingApplication]);

  const handleRequiredFields = (fields, state) => {
    for (const field of fields) {
      if (!state[field]) {
        setShowError(true);
        return false;
      }
    }
    setShowError(false);
    return true;
  };

  const findNextStep = (currentStep, direction, state) => {
    // console.log("currentStep:", currentStep);
    // console.log("direction:", direction);
    // console.log("state:", state);
    let stepIndex = stepConfig.findIndex(
      (config) => config.step === currentStep
    );

    // If moving forward, check for required fields.
    if (direction === 1) {
      const currentConfig = stepConfig[stepIndex];
      if (currentConfig.requiredFields) {
        if (
          !handleRequiredFields(currentConfig.requiredFields, state) ||
          showError
        ) {
          return null;
        }

        // addition check for email validation on step 1, state.email
        if (currentStep === 1 && !isValidEmail(state.email)) {
          console.log("email is invalid");
          setShowError(true);
          return null;
        }

        // Additional check for URL validation on specific step
        if (currentStep === 2 && !isValidHttpUrl(state.song_link)) {
          console.log("url is invalid");
          setShowError(true);
          return null;
        }
      }
    }

    while (true) {
      stepIndex += direction;
      if (stepIndex < 0 || stepIndex >= stepConfig.length) {
        return null; // Reached beginning or end
      }

      const skip = stepConfig[stepIndex].skipIf;
      if (!skip || !skip(state)) {
        return stepConfig[stepIndex].step; // Found a valid step
      }
    }
  };

  const generateTalentScoutSummary = async (formData) => {
    // fetch with axios from our api with the formData in the body to endpoint /ai/talent-scout-summary
    console.log("generateTalentScoutSummary with formData:", formData);
    const response = await axios.post(
      `${API_BASE_URL}/ai/talent-scout-summary`,
      formData
    );

    console.log("response.data:", response.data);

    return response.data;
  };

  // const generateOpportunityQualityScore = async (formData) => {
  //   // fetch with axios from our api with the formData in the body to endpoint /ai/talent-scout-summary
  //   const response = await axios.post(
  //     `${API_BASE_URL}/ai/opportunity-quality-score`,
  //     formData
  //   );

  //   console.log("response.data:", response.data);

  //   return response.data;
  // };

  // Use useEffect to watch for changes in the email field
  useEffect(() => {
    const fetchAndSetApplicationData = async () => {
      if (formData?.email && isValidEmail(formData?.email) && !testmode) {
        let fetchedContactData = await fetchContactDataByEmail(
          formData?.email,
          MM_CRM_LOCATION_ID
        );
        if (fetchedContactData?.breakthrough?.application?.fields) {
          setExistingApplication(
            fetchedContactData.breakthrough.application.fields
          );
          setContactData(fetchedContactData);
        } else {
          // Handle the case where there is no existing application
          // You can continue as normal or set some other state here
        }
      }
    };

    fetchAndSetApplicationData();
  }, [formData?.email, setContactData, testmode]);

  useEffect(() => {
    // Send an initial message to the parent indicating the iframe is ready
    window.parent.postMessage({ iframeReady: true }, "*");

    const allowedOrigins = [
      "https://modernmusician.me",
      "https://modern-musician.com",
      "https://streetteam.me",
    ];

    const isOriginAllowed = (origin) => {
      // Check if the event origin matches any of the allowed domains (including subdomains)
      return allowedOrigins.some(
        (allowedOrigin) =>
          origin === allowedOrigin ||
          origin.endsWith("." + allowedOrigin.split("//")[1])
      );
    };

    const handleMessage = (event) => {
      if (!isOriginAllowed(event.origin)) {
        console.error("Message from untrusted origin:", event.origin);
        return;
      }

      // Assuming if 'checkIframeReady' is in the message, it's a check from the parent
      if (event.data && event.data.checkIframeReady) {
        // The parent is checking if the iframe is ready, send confirmation back
        event.source.postMessage({ iframeReady: true }, event.origin);
      } else {
        // Handle URL parameters or other data types here
        console.log("Received data:", event.data);
        setParentUrlParams(event.data); // Adjust this function as per your implementation
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup function to remove event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // The empty dependency array ensures the effect runs only once on mount

  // console.log("existingApplication:", existingApplication);
  // console.log("contactData:", contactData);
  // console.log("contact:", contact);

  // useEffect to track changes in parentUrlParams
  // useEffect(() => {
  //   console.log("parentUrlParams:", parentUrlParams);
  // }, [parentUrlParams]);

  // track changes in hiddenFieldsData
  useEffect(() => {
    console.log("hiddenFieldsData:", hiddenFieldsData);
  }, [hiddenFieldsData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    // get last step number from stepConfig
    const lastStep = stepConfig[stepConfig.length - 1].step;

    // check to make sure all required form fields have been filled out before submitting
    if (currentStep !== lastStep) {
      let newStep = findNextStep(currentStep, 1, formData);
      if (newStep !== null) {
        setCurrentStep(newStep);
      }
      return;
    }

    setIsSubmitting(true);

    let fields = {};
    // add formData to fields object but add app__ to the keys
    Object.keys(formData).forEach((key) => {
      if (key === "name" || key === "email") {
        // if key is full name, turn it into just field[name]
        if (key === "name") {
          fields["name"] = formData[key];
        } else {
          fields[key] = formData[key];
        }
      }
      fields[`app__${key}`] = formData[key];
    });

    // Add hiddenFieldsData to fields object
    Object.keys(hiddenFieldsData).forEach((key) => {
      if (hiddenFieldsData[key]) {
        fields[key] = hiddenFieldsData[key]; // Add hidden fields with their original keys
      }
    });

    // add submit date to fields object
    fields["app__submit_date"] = format(new Date(), "MM-dd-yyyy");

    // if the subgenre is Thug, set the genre to "Thug"
    if (hipHopSubGenre === "Thug") {
      fields["app__genre"] = "Thug";
    }

    console.log("fields:", fields);

    // get artist_tier
    let predicted_artist_tier = calculateArtistTier(
      formData.resourceful,
      formData.investable_budget,
      formData.monthly_revenue
    );

    // console.log("predicted_artist_tier:", predicted_artist_tier);

    // create friendly lowercase url convention for artist_tier like this "emerging-artist"
    let predicted_artist_tier_friendly_url_name = predicted_artist_tier.name
      .split(" ")
      .join("-")
      .toLowerCase();

    fields["app__predicted_tier_name"] = predicted_artist_tier.name;

    // if unset, set their investable budget based on available budget
    // available budget based on whether they are willing to spend $1000/m or based on their job income

    if (!formData.investable_budget) {
      if (
        formData.resourceful === "Yes" &&
        formData.sustained_by_music === "No" &&
        formData.target_monthly_revenue
      ) {
        fields["app__investable_budget"] = formData.target_monthly_revenue;
      } else if (formData.sustained_by_music === "Yes") {
        fields["app__investable_budget"] =
          formData.target_monthly_revenue * 0.5;
      } else if (formData.resourceful === "Yes") {
        fields["app__investable_budget"] = 1000;
      }
    }

    // set redirect_url
    // if resourceful is yes, redirect to https://go.modernmusician.me/application-submitted?email=${formData.email}&predicted_artist_tier=${predicted_artist_tier}

    // if start_date is today, set readiness to "hot", if within 7 days, set readiness to "warm", if it's set to longer, set it to "cold".
    // use a case switch

    let startReadiness;

    switch (formData.start_date) {
      case format(new Date(), "MM-dd-yyyy"): // today
        startReadiness = "hot";
        break;
      case format(
        new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        "MM-dd-yyyy"
      ): // 7 days from now
        startReadiness = "warm";
        break;
      default:
        startReadiness = "cold";
    }

    console.log("startReadiness:", startReadiness);

    // set submit_date field and add to formData in a new object called opportunityFields
    let opportunityFields = {
      ...formData,
      genre: hipHopSubGenre === "Thug" ? "Thug" : formData.genre,
      submit_date: format(new Date(), "MM-dd-yyyy"),
    };

    let opportunity = await calculateOpportunityValue(opportunityFields);

    let knowsSecretPasscode = formData.secret_passcode
      ?.toLowerCase()
      .includes("robo") ||
      formData.secret_passcode?.toLowerCase().includes("music");

    console.log("opportunity_value:", opportunity.value);
    fields["app__total_deal_value"] = opportunity.total_deal_value;
    fields["app__predicted_conversion_rate"] =
      opportunity.predicted_conversion_rate;
    fields["app__opportunity_value"] = opportunity.value;

    let redirect_url;
    let outcomeUrlParams = `?email=${formData.email}&predicted_artist_tier=${predicted_artist_tier_friendly_url_name}`;

    let { qualified, disqualified, discovery } = outcomes;

    console.log("knowsSecretPass", knowsSecretPasscode);

    // if they are resourceful and start date is today, redirect to qualified link
    // else if they are resourceful and start date is later, redirect to discovery link
    // else redirect to disqualified link

    if (formData.resourceful === "Yes" && startReadiness === "hot" && knowsSecretPasscode) {
      redirect_url = qualified; // Redirect to qualified link
    } else if (formData.resourceful === "Yes" && startReadiness === "warm" && knowsSecretPasscode) {
      redirect_url = qualified; // Redirect to qualified link if warm and knows secret passcode
    } else if (!knowsSecretPasscode) {
      redirect_url = discovery; // Redirect to discovery link if they don't know the secret passcode
    } else {
      redirect_url = disqualified; // Redirect to disqualified link
    }

    // // if opportunity value is greater than 750 and they know the secret passcode and they can start today, redirect to qualified
    // if (
    //   opportunity.value > 750 &&
    //   knowsSecretPasscode &&
    //   startReadiness === "hot"
    // ) {
    //   redirect_url = qualified; // Redirect to qualified link
    // } else if (!knowsSecretPasscode && opportunity.value >= 750) {
    //   redirect_url = discovery; // Redirect to discovery link
    // } else {
    //   redirect_url = disqualified; // Redirect to disqualified link
    // }

    // Append URL parameters to redirect_url
    redirect_url += outcomeUrlParams;

    console.log("redirect_url:", redirect_url);

    await submitForm({
      form: {
        id: "breakthrough_application",
      },
      location: {
        id: MM_CRM_LOCATION_ID,
      },
      contact: {
        email: formData.email,
      },
      fields: fields,
    });

    // setIsGeneratingSummary(true);

    // send to websocket
    // AskArtistAi(ws, fields);

    // const artistSummary = await generateFrontFacingSummary(formData);
    // console.log(artistSummary);
    // setArtistSummary(artistSummary);

    // clean formData
    let cleanedFormData = cleanFormData(opportunityFields);

    console.log("cleanedFormData:", cleanedFormData);

    const talentScoutSummary =
      await generateTalentScoutSummary(cleanedFormData);
    // const opportunityQualityScore =
    //   await generateOpportunityQualityScore(formData); // will add this later...will be a gamechanger!
    console.log(talentScoutSummary);

    // redirect to the appropriate page

    if (window !== window.parent) {
      // window.alert("inside an iframe, redirecting to", redirect_url);
      // Inside an iframe, redirect the parent
      window.parent.location.replace(redirect_url);
    } else {
      // window.alert("not inside an iframe, redirecting to", redirect_url);
      // Not inside an iframe, redirect as usual
      window.location.replace(redirect_url);
    }

    //console.log(opportunityQualityScore);
    // setTalentScoutSummary(talentScoutSummary);

    // setIsGeneratingSummary(false);
    // setCompleted("application", formData);
  };

  const handleInputChange = (event) => {
    setShowError(false);
    const { name, value } = event.target;
    let updatedValue = value;

    if (name === "song_link" && isValidHttpUrl(value)) {
      // Prepend "https://" if not included
      if (!value.startsWith("http://") && !value.startsWith("https://")) {
        updatedValue = "https://" + value;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    if (name === "sustained_by_music") {
      setIsFullTimeMusicIncome(value === "Yes");
    }
    if (event.target.type === "radio") {
      if (event.target.id === "resourceful_yes") {
        setCurrentStep(16);
      } else setCurrentStep((prevStep) => prevStep + 1);
    }

    if (name === "resourceful") {
      setResourceful(value === "Yes");
      console.log("resourceful:", resourceful);
    }
    // if (name === "resourceful") {
    // setHasResources(value === "yes");
    // }
  };

  const TodayCustomInput = forwardRef(({ value, onClick }, ref) => {
    // Determine if the selected date is today
    const isToday = format(new Date(), "MM-dd-yyyy") === formData?.start_date;
    // console.log("isToday:", isToday);
    // console.log("formData?.start_date:", formData?.start_date);
    // console.log(
    //   "format(new Date(), 'MM-dd-yyyy'):",
    //   format(new Date(), "MM-dd-yyyy")
    // );

    // calculate fans_required based on amount needed to replace income or monthly revenue target if they're already making music full-time

    return (
      <button
        type="button"
        className="form-control d-flex align-items-center justify-content-between"
        onClick={onClick}
        ref={ref}
      >
        {isToday ? "Immediately" : value}
        <FontAwesomeIcon icon={faCalendarDay} />
      </button>
    );
  });

  // // On date change
  // const handleDateChange = (date) => {
  //   setstart_date(date);
  //   setIsToday(checkIsToday(date));
  // };

  let revenue_required = 2500; // Initialize with a default value

  if (formData.target_monthly_revenue) {
    let target_monthly_revenue = parseFloat(
      formData.target_monthly_revenue.replace(/,/g, "")
    );
    revenue_required = target_monthly_revenue;
  } else if (formData.monthly_revenue) {
    let monthly_revenue = formData.monthly_revenue;
    switch (monthly_revenue) {
      case "Under $100":
        revenue_required = 500;
        break;
      case "$100 to $1,000":
        revenue_required = 2500;
        break;
      case "$1,000 to $10,000":
        revenue_required = 25000;
        break;
      case "$10,000 to $100,000":
        revenue_required = 250000;
        break;
      case "Above $100,000":
        revenue_required = 2500000;
        break;
      default:
        revenue_required = 2500; // Default case
    }
  }

  // Calculate other variables based on revenue_required
  let target_monthly_revenue = revenue_required;
  let target_monthly_sales = revenue_required / 5;
  // let target_monthly_fans = target_monthly_sales * 10;
  // let target_monthly_streams = target_monthly_fans * 5;
  // let target_cost_per_stream = 0.1;
  // let budget_required = target_monthly_streams * target_cost_per_stream;

  // Convert to locale string
  let formatted_target_monthly_revenue =
    target_monthly_revenue.toLocaleString();
  let formatted_target_monthly_sales =
    Math.ceil(target_monthly_sales).toLocaleString();
  // let formatted_target_monthly_fans = target_monthly_fans.toLocaleString();
  // let formatted_target_monthly_streams =
  //   target_monthly_streams.toLocaleString();

  let goal_text = `If you bring in ${formatted_target_monthly_sales} fans who pay you $5 per month, you'll earn
            $${formatted_target_monthly_revenue}/m in revenue. With an experienced mentor, do you believe you can achieve
            this or more?`;

  // let goal_text = `${formatted_target_monthly_revenue} target revenue requires ${formatted_target_monthly_sales} sales per month, which requires ${formatted_target_monthly_fans} fans per month, which requires ${formatted_target_monthly_streams} streams per month, which requires a budget of $${budget_required.toLocaleString()} per month.`;

  const genres = [
    "Alternative",
    "Americana",
    "Christian",
    "Classical",
    "Comedy",
    "Country",
    "Dance",
    "Electronic",
    "Folk",
    "Hip-Hop",
    "Indie",
    "Jazz",
    "Latin",
    "Metal",
    "New Age",
    "Pop",
    "Rap",
    "Reggae",
    "R&B",
    "Rock"
  ];

  // Add state to manage the genre and sub-genre selection
  const [selectedGenre, setSelectedGenre] = useState("");
  const [hipHopSubGenre, setHipHopSubGenre] = useState("");

  // Function to handle genre selection
  const handleGenreChange = (event) => {
    const { value } = event.target;
    setSelectedGenre(value);
    setFormData((prevData) => ({
      ...prevData,
      genre: value,
    }));
  };

  // Function to handle Hip-Hop sub-genre selection
  const handleSubGenreChange = (event) => {
    const { value } = event.target;
    setHipHopSubGenre(value);
  };

  // // if the subgenre is Thug, set the genre to "Thug"
  // if (hipHopSubGenre === "Thug") {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     genre: "Thug",
  //   }));
  // }

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      {!existingApplication && !isLoading && (
        <Container
          className="py-4 d-flex justify-content-center flex-column"
          style={{ maxWidth: "600px", maxHeight: "600px" }}
        >
          <Form
            className="d-flex flex-column gap-3"
            onSubmit={handleSubmit}
          // style={{ flex: 1 }} // This makes the form take all available space

          // style={{ height: "600px !important" }}
          >
            {/* <ProgressBar now={(currentStep / 4) * 100} /> */}
            {currentStep === 0 && (
              <>
                <HiddenTrackingFields
                  onDataUpdate={setHiddenFieldsData}
                  parentUrlParams={parentUrlParams}
                  testmode={testmode}
                />
                <Form.Group>
                  <Form.Label className="fs-6">What's your name?</Form.Label>
                  <Form.Control
                    name="name"
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Type your name here..."
                    // defaultValue={owner_name}
                    // disabled={contactData?.first_name ? true : false}
                    value={formData.name || ""}
                  // autocomplete="name" // ...adding this helps browsers/iOS to autofill
                  />
                </Form.Group>
              </>
            )}
            {/* Step 1: Personal Info */}
            {currentStep === 1 && (
              <>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="fs-6">Email?</Form.Label>
                  <Form.Control
                    name="email"
                    onChange={handleInputChange}
                    type="email"
                    disabled={parentUrlParams?.email ? true : false}
                    placeholder="Type your email here..."
                    // defaultValue={`${existingApplication?.owner?.email || ""}`}
                    value={formData.email || ""}
                    // autocomplete="email" // Helps with autofilling email addresses
                    isInvalid={formData.email && !isValidEmail(formData.email)}
                  />
                </Form.Group>
              </>
            )}
            {/* Step 2: Music Info */}
            {currentStep === 2 && (
              <>
                <Form.Group>
                  <Form.Label className="fs-6">
                    Please share a link to stream one of your songs
                  </Form.Label>
                  <Form.Control
                    name="song_link"
                    onChange={handleInputChange}
                    type="url"
                    placeholder="http://example.com/listen"
                    value={formData.song_link || ""}
                    isInvalid={
                      formData.song_link && !isValidHttpUrl(formData.song_link)
                    }
                    onInvalid={(e) => {
                      console.log("url is invalid");
                      setShowError(true);
                      e.target.setCustomValidity("Please enter a valid URL");
                    }}
                  />
                </Form.Group>
              </>
            )}
            {/* Step 2: Music Info */}
            {/* Step 3: Which genre best describes your music? */}
            {currentStep === 3 && (
              <>
                <Form.Group>
                  <Form.Label className="fs-6">Which genre best describes your music?</Form.Label>
                  <Form.Control
                    as="select"
                    name="genre"
                    value={formData.genre || ""}
                    onChange={handleGenreChange}
                  >
                    <option value="" disabled>
                      Select a genre
                    </option>
                    {genres.map((genre) => (
                      <option key={genre} value={genre}>
                        {genre}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Conditional Hip-Hop/Rap follow-up question */}
                {(selectedGenre === "Rap" || selectedGenre === "Hip-Hop") && (
                  <Form.Group className="mt-3">
                    <Form.Label className="fs-6">
                    Which type of rap speaks to you the most?
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      id="Thug"
                      label="I vibe with rap that’s about power, street life, and doing whatever it takes to succeed (e.g., 50 Cent, Lil Durk)."
                      name="hipHopSubGenre"
                      value="Thug"
                      onChange={handleSubGenreChange}
                      checked={hipHopSubGenre === "Thug"}
                    />
                    <Form.Check
                      type="radio"
                      id="consciousRap"
                      label=" I like rap that focuses on self-improvement, social issues, and positive change (e.g., Kendrick Lamar, J. Cole)."
                      name="hipHopSubGenre"
                      value="Conscious Rap"
                      onChange={handleSubGenreChange}
                      checked={hipHopSubGenre === "Conscious Rap"}
                    />
                  </Form.Group>
                )}
              </>
            )}
            {currentStep === 4 && (
              <>
                <Form.Group>
                  <Form.Label className="fs-6">
                    Tell me briefly about your music career
                  </Form.Label>
                  <Form.Text className="text-muted ms-2">
                    {<br></br>}
                    We can only accept a limited number of sessions due to high
                    demand. What makes you different from the other applications
                    and why should we choose to speak with you?
                  </Form.Text>
                  <Form.Control
                    name="artist_description"
                    onChange={handleInputChange}
                    className="mt-3"
                    as="textarea"
                    rows={3}
                    value={formData.artist_description || ""}
                    placeholder="Type your answer here..."
                  />
                </Form.Group>
              </>
            )}
            {/* Step 3: Fanbase Size */}
            {currentStep === 5 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    What's the current size of your fanbase?
                  </Form.Label>
                  {/* List of radio buttons */}
                  {[
                    "Under 1,000",
                    "1,000 to 10,000",
                    "10,000 to 100,000",
                    "100,000 to 1,000,000",
                    "Above 1,000,000",
                  ].map((size) => (
                    <Form.Check
                      key={size}
                      id={`${size.replace(/\s/g, "")}fans`}
                      type="radio"
                      label={size}
                      name="fanbase_size"
                      value={size}
                      onChange={handleInputChange}
                      checked={formData?.fanbase_size === size}
                    />
                  ))}
                </FormGroup>
              </>
            )}
            {/* Step 4: Monthly Revenue */}
            {currentStep === 6 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    On average, how much are you making per month from music?
                  </Form.Label>
                  {/* List of radio buttons */}
                  {[
                    "Under $100",
                    "$100 to $1,000",
                    "$1,000 to $10,000",
                    "$10,000 to $100,000",
                    "Above $100,000",
                  ].map((revenue) => (
                    <Form.Check
                      key={revenue}
                      id={`${revenue.replace(/\s/g, "")}`}
                      type="radio"
                      label={revenue}
                      name="monthly_revenue"
                      value={revenue}
                      onChange={handleInputChange}
                      checked={revenue === formData?.monthly_revenue}
                    />
                  ))}
                </FormGroup>
              </>
            )}
            {/* Step 6a: Day job? */}
            {currentStep === 7 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    Is your primary source of income from music?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    id="sustained_by_music_yes"
                    name="sustained_by_music"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData?.sustained_by_music === "Yes"}
                  />
                  <Form.Check
                    type="radio"
                    id="sustained_by_music_no"
                    label="No"
                    name="sustained_by_music"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData?.sustained_by_music === "No"}
                  />
                </FormGroup>
              </>
            )}
            {/* Step 6b: How much to transition? */}
            {currentStep === 8 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">
                    {isFullTimeMusicIncome ? (
                      "What's your target monthly revenue with your music?"
                    ) : (
                      <>
                        How much would you need to make per month to{" "}
                        <u>fully replace</u> your existing income from other
                        sources?
                      </>
                    )}
                  </Form.Label>
                  <div
                    className="d-flex justify-content-center align-items-center rounded border"
                    style={
                      {
                        // display: "flex",
                        // alignItems: "center",
                        // maxWidth: "300px",
                        // border: "1px solid ",
                        // borderRadius: "4px",
                        // backgroundColor: "#f8f9fa",
                      }
                    }
                  >
                    <span
                      className="bg-light-gray"
                      style={{ padding: "6px 12px" }}
                    >
                      $
                    </span>
                    <Form.Control
                      style={{
                        textAlign: "right",
                        flex: "1",
                        border: "none",
                        boxShadow: "none",
                      }}
                      type="number"
                      placeholder="0.00"
                      name="target_monthly_revenue"
                      onChange={handleInputChange}
                      value={formData.target_monthly_revenue || ""}
                      step="100"
                    />
                    <span
                      className="bg-light-gray"
                      style={{ padding: "6px 12px" }}
                    >
                      per month
                    </span>
                  </div>
                </FormGroup>
              </>
            )}
            {/* Step 8: Looking back on the past 12 months */}
            {currentStep === 9 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">
                    If we were having a discussion a year from today, and you
                    were looking back on the past 12 months, what would have had
                    to happen in both your life and career, for you to be
                    excited about your progress?
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Type your answer here..."
                    name="annual_goals"
                    value={formData.annual_goals || ""}
                    rows={4}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </>
            )}
            {/* Step 9: Factors stopping from reaching goals */}
            {currentStep === 10 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">
                    Be 100% honest - What do you think is stopping you from
                    reaching your goals? (The more specific you are here the
                    faster we'll be able to get you clarity during our call
                    together)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Type your answer here..."
                    name="roadblocks"
                    rows={3}
                    value={formData.roadblocks || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </>
            )}
            {/* Step 10: Interest in coaching */}
            {currentStep === 11 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">
                    I'm looking for a few coaching clients to mentor personally
                    with me and my team. If you're a good fit, would you be
                    interested in working together?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    id="coachable_yes"
                    label="Yes"
                    name="coachable"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData?.coachable === "Yes"}
                  />
                  <Form.Check
                    type="radio"
                    id="coachable_no"
                    label="No"
                    name="coachable"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData?.coachable === "No"}
                  />
                </FormGroup>
              </>
            )}
            {/* Step 5: Daily Time Commitment */}
            {currentStep === 12 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">
                    How many hours do you (or a team member) have available to
                    focus on your music per day?
                  </Form.Label>
                  {/* Map through time options */}
                  {[
                    "Less than 1 hour",
                    "1 to 2 hours",
                    "2 to 5 hours",
                    "5 to 10 hours",
                    "Above 10 hours",
                  ].map((timeOption) => (
                    <Form.Check
                      key={timeOption}
                      id={`investable_time_${timeOption
                        .replace(/\s+/g, "_")
                        .toLowerCase()}`}
                      type="radio"
                      label={timeOption}
                      name="investable_time"
                      value={timeOption}
                      onChange={handleInputChange}
                      checked={timeOption === formData?.investable_time}
                    />
                  ))}
                </FormGroup>
              </>
            )}
            {/* Step 7: belief */}
            {currentStep === 13 && (
              <>
                <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                  <Form.Label className="fs-6">{goal_text}</Form.Label>
                  <Form.Check
                    type="radio"
                    id="belief_yes"
                    label="Yes"
                    name="belief"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData?.belief === "Yes"}
                  />
                  <Form.Check
                    type="radio"
                    id="belief_no"
                    label="No"
                    name="belief"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData?.belief === "No"}
                  />
                </FormGroup>
              </>
            )}
            {/* Resourceful? */}
            {currentStep === 14 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    The minimum investment for 1:1 coaching with Modern Musician
                    is $5k. During our call, if we both decide we'd like to move
                    forward, are you able to do this? (We also have a $1500/mo
                    payment plan available)
                  </Form.Label>
                  <Form.Check
                    id="resourceful_yes"
                    type="radio"
                    label="Yes"
                    name="resourceful"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={formData?.resourceful === "Yes"}
                  />
                  <Form.Check
                    id="resourceful_no"
                    type="radio"
                    label="No"
                    name="resourceful"
                    value="No"
                    onChange={handleInputChange}
                    checked={formData?.resourceful === "No"}
                  />
                </FormGroup>
              </>
            )}
            {/* Step 6a: Day job? */}
            {currentStep === 15 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    What is your maximum budget per month available to invest in
                    growing your music career? (including recording, music
                    videos, promotion, etc.)
                  </Form.Label>
                  <FormGroup className="d-flex flex-column gap-2 position-relative justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center rounded border"
                      style={
                        {
                          // display: "flex",
                          // alignItems: "center",
                          // maxWidth: "300px",
                          // border: "1px solid ",
                          // borderRadius: "4px",
                          // backgroundColor: "#f8f9fa",
                        }
                      }
                    >
                      <span
                        className="bg-light-gray"
                        style={{ padding: "6px 12px" }}
                      >
                        $
                      </span>
                      <Form.Control
                        style={{
                          textAlign: "right",
                          flex: "1",
                          border: "none",
                          boxShadow: "none",
                        }}
                        type="number"
                        placeholder="0.00"
                        name="investable_budget"
                        onChange={handleInputChange}
                        value={formData.investable_budget || ""}
                        step="100"
                      />
                      <span
                        className="bg-light-gray"
                        style={{ padding: "6px 12px" }}
                      >
                        per month
                      </span>
                    </div>
                  </FormGroup>{" "}
                </FormGroup>
              </>
            )}
            {/* Resourceful? */}
            {currentStep === 16 && (
              <>
                <FormGroup className="d-flex flex-column gap-2">
                  <Form.Label className="fs-6">
                    If you are accepted, how soon can you get started?
                  </Form.Label>
                  {/* {console.log("Initial start date:", formData?.start_date)} */}
                  <DatePicker
                    selected={
                      formData?.start_date
                        ? new Date(convertDateToSlashes(formData?.start_date))
                        : null
                    }
                    onChange={(date) => {
                      // console.log("Selected date:", date);
                      if (date instanceof Date) {
                        const formattedDate = format(date, "MM-dd-yyyy");
                        // console.log("Formatted date:", formattedDate);
                        setFormData((prevData) => {
                          // console.log("Previous formData:", prevData);
                          return {
                            ...prevData,
                            start_date: formattedDate,
                          };
                        });
                      }
                    }}
                    customInput={<TodayCustomInput />}
                    dateFormat="MM-dd-yyyy"
                    disabledKeyboardNavigation
                    dayClassName={(date) => {
                      const selectedDate = formData?.start_date
                        ? new Date(convertDateToSlashes(formData?.start_date))
                        : null;
                      return selectedDate &&
                        date.toDateString() === selectedDate.toDateString()
                        ? "bg-secondary"
                        : "";
                    }}
                  />
                </FormGroup>
              </>
            )}

            {/* Step 16: Secret Passcode */}
            {currentStep === 17 && (
              <Form.Group>
                <Form.Label className="fs-6">
                  Last question - what's the secret passcode?
                </Form.Label>
                <Form.Text className="text-muted ms-2 mb-2">
                  {<br></br>}
                  (Just leave this blank if you don't know)
                </Form.Text>
                <Form.Control
                  className="mt-2"
                  name="secret_passcode"
                  type="text"
                  placeholder="Enter passcode here..."
                  onChange={handleInputChange}
                  value={formData.secret_passcode || ""}
                />
              </Form.Group>
            )}

            <div className="d-flex justify-content-center gap-3 mt-2">
              {currentStep !== 0 && (
                <button
                  type="button"
                  className="custom-back-stepper"
                  onClick={() => {
                    const newStep = findNextStep(currentStep, -1, formData);
                    if (newStep !== null) {
                      setCurrentStep(newStep);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="me-2"
                    size="sm"
                  />{" "}
                  Previous
                </button>
              )}

              {currentStep !== 17 ? (
                <button
                  type="button"
                  className="custom-next-stepper "
                  onClick={() => {
                    const newStep = findNextStep(currentStep, 1, formData);
                    if (newStep !== null) {
                      setCurrentStep(newStep);
                    }
                  }}
                >
                  Next{" "}
                  <FontAwesomeIcon
                    size="sm"
                    icon={faAngleRight}
                    className="ms-2"
                  />
                </button>
              ) : (
                <Button type="submit">
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            </div>
            {showError && (
              <span className="text-danger text-center">
                * Please fill in all required fields
              </span>
            )}
          </Form>
        </Container>
      )}

      {existingApplication && !isLoading && <ApplicationReviewWidget />}

      {/* {isGeneratingSummary && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {talentScoutSummary && (
        <>
          <hr />
          Done!
        </>
      )} */}
    </>
  );
};

export default ArtistApplication;
